import React from "react"

const TopBar = ({ title, children, small, xsmall }) => {
  const additionalClass = xsmall ? 'c-topbar--x-small' : small ? 'c-topbar--small' : ''
  return (
    <div className={`c-topbar ${additionalClass}`}>
      <div className={`a-container c-topbar__content`}>
        <h4 className="c-topbar__title a-section__title--alt">{title}</h4>
        {children}
      </div>
    </div>
  )
}

export default TopBar
