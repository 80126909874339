import React from "react"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import { orderBy } from "lodash"

import { localize } from "../utils"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ProductLeaf from "../components/product-leaf"
import TopBar from "../components/topbar"
import SectionChar from "../components/section-char"

const CategoryPage = ({ pageContext, intl }) => {
  const {category, blocks, products} = pageContext

  const locCategory = localize(category, intl.locale)
  const localizedBlocks = blocks.map(blockData =>
    localize(blockData, intl.locale)
  )

  const isFromMarketing = typeof window !== "undefined" ? window.location.search.includes('mkt=1') : false

  const mainProducts = products
    .map(product => localize(product, intl.locale))
    .filter(product => !!product.short_description && !product.hidden)
  const secondaryProducts = products
    .map(product => localize(product, intl.locale))
    .filter(product => !product.short_description && !product.hidden)

  const categorySections = orderBy(localizedBlocks.filter(block => block.page === "categoria" && parseInt(block.section) === locCategory.directusId), 'order')

  return (
    <Layout name="p-category">
      <Seo title={locCategory.name} />
      <TopBar title={locCategory.name}>
        <p className="p-category__abstract">{locCategory.description}</p>
      </TopBar>
      <div className="a-container p-category-container">
        {/* {categorySections.map(section => {
          return <SectionChar section={section}></SectionChar>
        })} */}
        {mainProducts.map(product => {
          return (
            <>
              <ProductLeaf
                item={{
                  ...product,
                  description: product.short_description,
                  extra: product.size,
                }}
                onClick={
                  product.long_description && !isFromMarketing
                    ? () =>
                        navigate(`/prodotto/${product.item_id}`)
                    : undefined
                }
              />
            </>
          )
        })}
      </div>
      {secondaryProducts.length > 0 && (
        <div className="a-container p-category-others a-container--fluidMobile">
          <h4 className="a-section__title">
            {intl.formatMessage({ id: "terms.same_family" })}
          </h4>
          <div className="p-category-others__content">
            {secondaryProducts.map(product => {
              return (
                <>
                  <ProductLeaf
                    options={{ size: 165, compact: true }}
                    item={product}
                  />
                </>
              )
            })}
          </div>
        </div>
      )}
    </Layout>
  )
}

export default injectIntl(CategoryPage)
