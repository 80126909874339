import React from "react"

const ColorCircle = ({ colors }) => {
  if (!colors) return null
  if(colors.length < 2) colors.push(colors[0])
  return (
    <div
      style={{
        width: "20px",
        height: "20px",
        background: `linear-gradient( 90deg, ${colors[0]}, ${colors[0]} 50%, ${colors[1]} 50% )`,
        borderRadius: "50%",
        border: `1px solid ${colors[0]}`,
      }}
    ></div>
  )
}

export default ColorCircle
