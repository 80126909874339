import React from "react"
import Image from "./image"

const SectionChar = ({ section }) => {
  return (
    <div className={`c-sectionchar ${section.type.endsWith("_dx") ? 'is-right' : ''}`}>
        <div className="c-sectionchar__image position-relative">
          {section.images && <Image image={section.images[0]}></Image>}
        </div>
        <div className="c-sectionchar__info">
          <div className="c-sectionchar__title">{section.title}</div>

          <div className="c-sectionchar__text">{section.body}</div>
        </div>
      </div>
  )
}

export default SectionChar
