import React from "react"

import BlueShape from "./blue-shape"
import ColorCircle from "./color-circle"
import IconButton from "./icon-button"


const ProductLeaf = ({ item, options, onClick }) => {
  if (!item) return null
  if (!options) options = { size: 260, compact: false }
  if (!options.size) options.size = 260
  const colors = item.colors ? item.colors.split(',').map(groups => groups.split('/')) : []

  return (
    <div
      className={options.compact ? "c-productleaf-compact" : "c-productleaf"}
      style={onClick ? { cursor: "pointer" } : {}}
      onClick={onClick}
      onKeyDown={null}
    >
      <div className="c-productleaf__image position-relative">
        <BlueShape size={options.size} image={item.image} />
        {onClick && item.long_description && !options.compact && <IconButton icon="UilArrowRight" size="26px" class="a-btn--square" />}
      </div>
      {!options.compact && (
        <div className="c-productleaf__info">
          <div className="c-productleaf__title">{item.name}</div>
          <div className="c-productleaf__extra">{item.extra}</div>
          <div className="c-productleaf__text">{item.description}</div>
          <div className="c-productleaf__colors">{colors.map(col => <ColorCircle colors={col} key={col}/>)}</div>
        </div>
      )}
      {options.compact && (
        <div
          className="c-productleaf__bottom-text"
          style={{ maxWidth: `${options.size}px` }}
        >
          {item.name}
        </div>
      )}
    </div>
  )
}

export default ProductLeaf
