import React from "react"
import * as Unicons from "@iconscout/react-unicons"

const IconButton = props => {
  const Icon = Unicons[props.icon]
  return (
    <div className={props.class} onClick={props.onClick}>
      <Icon size={props.size} />
    </div>
  )
}

export default IconButton
