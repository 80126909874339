import React from "react"

import Image from "./image"

const BlueShape = ({ image, size = 260, label }) => {
  const pxSize = `${size}px`
  return (
    <div className="c-blueshape" style={{ width: pxSize, height: pxSize }}>
      {label && <div class="ribbon">{label}</div>}
      {image && <Image image={image} center size="medium" />}
    </div>
  )
}

export default BlueShape
